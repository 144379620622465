import { Card, Space } from "antd"
const { Meta } = Card
export const ServiceItem = ({ service }) => {
    return <Space direction="vertical" align="center">
        <Card
            align="center"
            hoverable
            style={{ margin: "0 10px" }}
            cover={<img alt={service.name} src={service.icon?.url} style={{ width: 150, height: 150, padding: "20px" }} />}
        >
            <Meta title={service.name} />
        </Card>
    </Space>
}