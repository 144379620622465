import { Avatar, Space, Typography } from "antd"

export const Header = ({ title, bathroom }) => {
    return (
        <Space direction="vertical" align="center">
            <Typography>{bathroom}</Typography>
            <Typography>{title}</Typography>
            <Avatar
                style={{ margin: "20px auto" }}
                shape="square"
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                alt={title} src="./images/logo.png" />
        </Space>
    )
}