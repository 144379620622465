import { Space } from "antd"
import { ToiletItem } from "./ToiletItem"

export const Toilets = ({ toilets, confirm }) => {
    return (
        <Space direction="vertical" align="center">
            <Space size={16} wrap>
                {toilets?.map(e => {
                    return <a role="button" onClick={() => confirm(e)}>
                        <ToiletItem toilet={e} />
                    </a>
                })}
            </Space>
        </Space>
    )
}