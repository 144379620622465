import { Avatar, Space, Typography } from "antd"
import { RateItem } from "./RateItem"

const { Text } = Typography

export const Rating = ({ lastTime, ratings, confirm }) => {
    return (
        <Space direction="vertical" align="center">
            <Text style={{ textAlign: "center", display: "block" }}>Last serviced at <br />{lastTime}</Text>
            <Text strong style={{ padding: "30px", display: "inline-block" }}>Please rate this space</Text>
            <Space size={16} wrap>
                {ratings?.map(e => {
                    return <a role="button" onClick={() => confirm(e)}>
                        <RateItem rating={e} />
                    </a>
                })}
            </Space>
        </Space>
    )
}